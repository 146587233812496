<template>
  <div class="leodyBox">
    <div class="pshow contentCard clearfix">
      <div class="left"></div>
      <div class="right">
        <p class="title">{{ founder.name }}</p>
        <p class="content">{{ founder.introduce }}</p>
      </div>
    </div>

    <div class="mshow contentCard">
      <div class="ceo-img">
        <img :src="founder.img" alt="">
      </div>

      <div class="ceo-text">
        <p class="content">
          <b>{{ founder.name }}</b>，{{ founder.introduce }} </p>
      </div>
    </div>
  </div>
</template>

<script>
import { founder } from '@/assets/js/fixed_data'

export default {
  name: "founder",
  data() {
    return {
      founder: founder
    }
  }
}
</script>

<style scoped lang="less">
.leodyBox{
  .contentCard{
    width: 8.8rem;
    height: 3.82rem;
    margin: 1.13rem auto 0 auto;
    position: relative;
    background:rgba(255,255,255,1);
    box-shadow:0px 2px 36px 0px rgba(210,210,255,0.77);
    border-radius:6px;
    padding: 0 .3rem;
    .left{
      width: 2.5rem;
      height: 3.54rem;
      background: url("https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/WechatIMG9.jpeg") no-repeat;
      background-position: 54%;
      background-size: 250% 100%;
      position: relative;
      top: -0.38rem;
      border-radius: 6px;
      transition: all .5s;
      box-shadow: 0px 3px 15px 3px #adaeaf;
      &:hover{
        background-size: 265% 105%;
        box-shadow: 0px 5px 15px 5px #adaeaf;
      }
      .leodyName{
        text-align: center;
        font-size:.18rem;
        font-weight:500;
        color:rgba(255,255,255,1);
      }
      .leodyRole{
        text-align: center;
        font-size:.15rem;
        font-weight:400;
        color:rgba(255,255,255,1);
        margin-top: .1rem;
      }
    }
    .right{
      width:5.96rem;
      .title{
        font-size:.16rem;
        font-weight:500;
        color:rgba(51,51,51,1);
        padding: .4rem 0 .2rem;
      }
      .content{
        font-size:.14rem;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:26px;
      }
    }
  }
}

.pshow {
  display: block;
}

.mshow {
  display: none;
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .pshow {
    display: none;
  }

  .mshow {
    display: block;
  }

  .leodyBox {
    .contentCard {
      width: 100%;
      height: 3.82rem;
      margin: 0 auto;
      background: transparent;
      box-shadow: none;
      padding: 0;

      .ceo-img {
        width: 100%;
        margin: 0 auto;
        background-size: 100% 100%;
        text-align: center;
        font-size: 0;

        img {
          width: calc(100% - .48rem);
          margin: .18rem 0 .3rem;
          border-radius: 4px;
        }

        .leodyName {
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          color: rgba(255, 255, 255, 1);
        }

        .leodyRole {
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
          margin-top: .1rem;
        }
      }

      .ceo-text {
        width: calc(100% - 0.4rem);
        padding: 0 .2rem;

        .title {
          font-size: 16px;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          padding: 0 0 .2rem;
        }

        .content {
          font-size: 14px;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
          line-height: 26px;
        }
      }
    }
  }
}
</style>
