<style lang="less" scoped>
  .timeLineBox{
    position: relative;
    left: 130px;
    text-align: left;
    width: 550px;
    margin: 0 auto;
    font-size: 12px;
    .timeItem{
      position: relative;
      .line-time{
        width:75px;
        height:28px;
        font-size:20px;
        font-family:PingFangSC-Semibold,PingFang SC;
        font-weight:600;
        color:rgba(119,118,255,1);
        line-height:28px;
        display: inline-block;
        position: absolute;
        z-index: 11;
        left: -1rem;
        top: -12px;
      }
      .line-point{
        width: 16px;
        height: 16px;
        border-radius: 50%;
        position: relative;
        margin: 5px;
        left: -9px;
      }
      .line-line{
        width: 8px;
        background: #7776ffcc;
        border-radius: 6px;
        display: inline-block;
      }
      .line-content{
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
        position: absolute;
        left: 62px;
        top: 0;
        p{
          margin-bottom: 15px;
        }
      }
    }

  }
  .abouts-btnBox{
    font-size: 0;
    .abouts-btn{
      margin-top: .33rem;
      font-size:12px;
      font-weight:400;
      color:#fff;
      background-color: #7776FF;
    }
  }
  .pshow{
    display: block;
  }
  @media screen and (min-width: 320px) and (max-width: 992px) {
    .pshow{
      display: none;
    }
    .timeLineBox{
      position: relative;
      left: 44px;
      text-align: left;
      width: calc(100% - 44px);
      font-size: 12px;
      .timeItem{
        position: relative;
        .line-time{
          height:20px;
          font-size:16px;
          line-height:20px;
          display: inline-block;
          position: absolute;
          z-index: 11;
          left: -60px;
          top: -2px;
        }
        .line-point{
          width: 16px;
          height: 16px;
          border-radius: 50%;
          position: relative;
          margin: 5px;
          left: -9px;
        }
        .line-line{
          width: 8px;
          background: #7776ffcc;
          border-radius: 6px;
          display: inline-block;
        }
        .line-content{
          width: 3.13rem;
          /*overflow: hidden;*/
          /*text-overflow:ellipsis;*/
          /*white-space: nowrap;*/
          height: 22px;
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
          position: absolute;
          left: 24px;
          top: 0;
          p{
            margin-bottom: 15px;
          }
        }
      }

    }
    .abouts-btnBox{
      font-size: 0;
      .abouts-btn{
        margin-top: .33rem;
        font-size:12px;
        font-weight:400;
        color:#fff;
        background-color: #7776FF;
      }
    }
  }
</style>
<template>
  <div class="timeLineBox">
    <div class="timeItem" v-for="(val,ind) in activities0" :key="ind+'timeline'">
      <div class="line-time">
        {{val.timestamp}}
      </div>
      <div :style="'background-color:'+val.color" class="line-point"></div>
      <div :style="'background-color:'+val.color+';height:'+val.lineHeight+'px'" class="line-line"></div>

      <div class="line-content">
        <p :style="'width:'+value.width+'px'"
           v-for="(value,index) in val.content"
           :key="index+'content'">
          <u-animate-container >
            <u-animate name="fadeInUp" :delay="index/3+'s'" duration="0.5s" :iteration="1" :offset="0" animateClass="animated" :begin="false" class="abouts-p5 p5-right">
              {{value.text}}
            </u-animate>
          </u-animate-container>
        </p>
      </div>
    </div>

<!--    <div class="pshow abouts-btnBox">-->
<!--      <el-button @click="moreBtn" class="abouts-btn" round>{{isActive}}</el-button>-->
<!--    </div>-->
  </div>
</template>

<script>
  export default {
    data () {
      return {
        activities0:[],
        activities: [
          {
            content: [
              {
                text:'迈吉客科技年底注册成立',
                width:230,
              }
            ],
            timestamp: '2014年',
            color: '#7776ff',
            lineHeight:30
          },
          {
            content: [
              {
                text:'第四届中国创新创业大赛优胜奖',
                width:230,
              },
              {
                text: '上海市科委最高创新资金扶持',
                width:230,
              }
            ],
            timestamp: '2015年',
            color: '#76FFE1',
            lineHeight:60
          },
          {
            content: [
              {
                text:'北京市科委创新资金扶持',
                width:230,
              },
              {
                text:'首届北京市文化创意创新创业大赛第二名',
                width:230,
              },
              {
                text:'北京市最具投资价值文创企业50强',
                width:230,
              },
              {
                text:'第五届“东沙湖杯”千人计划创业大赛二等奖',
                width:230,
              },
              {
                text:'杨浦创业之星 “ 创业之星 ”',
                width:230,
              },
              {
                text:'中国创客四十强',
                width:230,
              }
            ],
            timestamp: '2016年',
            color: '#FFD230',
            lineHeight:210,
          },
          {
            content: [
              {
                text:'中关村高端领军人才',
                width:230,
              },
              {
                text:'中关村雏鹰计划专项扶持资金',
                width:230,
              },
              {
                text:'上海杨浦区创业之星 “ 创业菁英 ”',
                width:230,
              },
              {
                text:'第六届中国创新创业大赛成长组优秀企业',
                width:230,
              },
              {
                text:'"海英人才"及奖金奖励',
                width:230,
              },

              {
                text:'"创业先锋 2017"20强',
                width:230,
              },
              {
                text:'《创意中国》85%全场整季最高支持率，获最具投资价值奖',
                width:230,
              },
              {
                text:'苏州工业园区科技领军人才',
                width:230,
              }
            ],
            timestamp: '2017年',
            color: '#FF915A',
            lineHeight:310,
          },

          {
            content: [
              {
                text:'完成PreB轮融资,由国内知名母基金盛景旗下两支基金联合投资',
                width:230,
              },
              {
                text: '第三届中关村文化创意产业十大创新人物中关村前沿技术企业',
                width:230,
              },
              {
                text: '第七届中国财经峰会2018最具创新力企业',
                width:230,
              },
              {
                text: '中国技术创业协会科技创业贡献奖',
                width:230,
              }
            ],
            timestamp: '2018年',
            color: '#e63c86',
            lineHeight:180
          },
          {
            content: [
              {
                text:'入选成为动漫游戏产业协会理事会员单位',
                width:230,
              },
              {
                text:'北京市科学技术委员会',
                width:230,
              },
              {
                text:'科技型中小企业促进项目',
                width:230,
              },
              {
                text:'中国文创新品牌榜50强',
                width:230,
              },
              {
                text:'国家高新技术企业',
                width:230,
              },
              {
                text:'金葫芦奖“最具潜力投资价值机构”',
                width:230,
              },
              {
                text:'中国科技网/科技日报社“新锐科技企业”',
                width:230,
              },
              {
                text:'2019中国文化产业年度人物Top100',
                width:230,
              },
            ],
            lineHeight:260,
            timestamp: '2019年',
            color: '#7776ff',
          },
        ],
        // isActive: '加载更多',
        activitiesPc: [
          {
            content: [
              {
                text:'迈吉客科技年底注册成立',
                width:420,
              }
            ],
            timestamp: '2014年',
            color: '#7776ff',
            lineHeight:40
          },
          {
            content: [
              {
                text:'第四届中国创新创业大赛优胜奖',
                width:420,
              },
              {
                text: '上海市科委最高创新资金扶持',
                width:420,
              }
            ],
            timestamp: '2015年',
            color: '#76FFE1',
            lineHeight:80
          },
          {
            content: [
              {
                text:'北京市科委创新资金扶持',
                width:420,
              },
              {
                text:'首届北京市文化创意创新创业大赛第二名',
                width:420,
              },
              {
                text:'北京市最具投资价值文创企业50强',
                width:420,
              },
              {
                text:'第五届“东沙湖杯”千人计划创业大赛二等奖',
                width:420,
              },
              {
                text:'杨浦创业之星 “ 创业之星 ”',
                width:420,
              },
              {
                text:'中国创客四十强',
                width:420,
              }
            ],
            timestamp: '2016年',
            color: '#FFD230',
            lineHeight:240,
          },
          {
            content: [
              {
                text:'中关村高端领军人才',
                width:300,
              },
              {
                text:'中关村雏鹰计划专项扶持资金',
                width:300,
              },
              {
                text:'上海杨浦区创业之星 “ 创业菁英 ”',
                width:420,
              },
              {
                text:'第六届中国创新创业大赛成长组优秀企业',
                width:300,
              },
              {
                text:'"海英人才"及奖金奖励',
                width:300,
              },
              {
                text:'"创业先锋 2017"20强 ',
                width:300,
              },
              {
                text:'《创意中国》85%全场整季最高支持率，获最具投资价值奖',
                width:500,
              },
              {
                text:'苏州工业园区科技领军人才',
                width:420,
              }
            ],
            timestamp: '2017年',
            color: '#FF915A',
            lineHeight:310,
          },

          {
            content: [
              {
                text:'完成PreB轮融资,由国内知名母基金盛景旗下两支基金联合投资',
                width:500,
              },
              {
                text: '第三届中关村文化创意产业十大创新人物中关村前沿技术企业',
                width:500,
              },
              {
                text: '第七届中国财经峰会2018最具创新力企业',
                width:420,
              },
              {
                text: '中国技术创业协会科技创业贡献奖',
                width:420,
              }
            ],
            timestamp: '2018年',
            color: '#e63c86',
            lineHeight:160
          },
          {
            content: [
              {
                text:'入选成为动漫游戏产业协会理事会员单位',
                width:420,
              },
              {
                text:'北京市科学技术委员会',
                width:420,
              },
              {
                text:'科技型中小企业促进项目',
                width:420,
              },
              {
                text:'中国文创新品牌榜50强',
                width:420,
              },
              {
                text:'国家高新技术企业',
                width:420,
              },
              {
                text:'金葫芦奖“最具潜力投资价值机构”',
                width:420,
              },
              {
                text:'中国科技网/科技日报社“新锐科技企业”',
                width:420,
              },
              {
                text:'2019中国文化产业年度人物Top100',
                width:420,
              },
            ],
            lineHeight:260,
            timestamp: '2019年',
            color: '#7776ff',
          },
        ],

      }
    },
    mounted () {
      this.activities0 = JSON.parse(JSON.stringify(this.activitiesPc));
      if(this.isMobile()){
        this.activities0 = JSON.parse(JSON.stringify(this.activities));
      }
    },
    methods: {
      // 添加判断移动端方法
      isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
        return flag;
      },

      // moreBtn(){
      //   if(this.isActive==="加载更多"){
      //     this.activities0 = JSON.parse(JSON.stringify(this.activities));
      //     this.isActive="收起";
      //   }else{
      //     this.activities0 = JSON.parse(JSON.stringify(this.activities));
      //     this.activities0.splice(3)
      //     this.isActive="加载更多";
      //   }
      //   this.$forceUpdate();
      // },
    }
  }
</script>
