<template>
  <div class="bgBox" :style="{backgroundImage:'url('+backgroundImage+')'}">
    <div class="titleBgBox-title clearfix">
      <u-animate-container v-for="(item,index) in options" :key="index">
        <u-animate :name="item.animate" :delay="item.delay" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
          <span :class="index>0?'':'content'">{{ item.content }}</span>
        </u-animate>
      </u-animate-container>
    </div>
    <u-animate-container>
      <u-animate name="fadeInUp" delay="0.7s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
        <p class="text">{{ slogan }}</p>
      </u-animate>
    </u-animate-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "index",
  props: {
    pcImage: String,
    mobileImage: String,
    options: Array,
    slogan: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters(['clientWidth']),
    backgroundImage() {
      return this.clientWidth < 768 ? this.mobileImage : this.pcImage
    }
  }
}
</script>

<style lang="less" scoped>
.bgBox {
  width: 100%;
  height: 4.7rem;
  //background-size: 102% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: -3px;
  text-align: center;

  .titleBgBox-title {
    padding: 2rem 0 0 0;
    display: flex;
    justify-content: center;
    font-size: 40px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    text-shadow: 0px 2px 30px rgba(0, 0, 0, 0.75);

    .content {
      margin-right: 20px;
    }
  }

  .text {
    padding-top: 28px;
    height: 30px;
    font-size: 20px;
    color: rgba(255, 255, 255, 1);
    line-height: 30px;
    letter-spacing: 3px;
  }
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .bgBox {
    background-repeat: no-repeat;
    background-size: cover;
    height: 3.7rem;

    .titleBgBox-title {
      padding: 1.6rem 0 0 0;
      font-size: .3rem;
    }

    .text {
      padding-top: .18rem;
      font-size: .15rem;
    }
  }
}

</style>
