<template>
  <div class="aboutsBox">
    <u-animate-container>
      <u-animate name="fadeInUp" delay="0s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
        <div class="profileBox1">
          <img v-if="!isMobile" class="chatu01" :src="chatu01" alt="">
          <img v-if="!isMobile" class="chatu02" :src="chatu02" alt="">
          <div class="profileBox">
            <p class="Profile-title">公司简介</p>
            <p class="Profile-sub">Company Profile</p>
            <p class="Profile-content">
              <!--              迈吉客科技是一家专注于人工智能技术应用于计算机视觉三维混合现实领域的成长型公司，同时也是国家级高新技术企业和中关村前沿技术企业，在实现虚拟和现实的仿真融合与交互控制方面有深厚的积累。作为内容科技/文娱科技的引领者和创新者，迈吉客科技率先将人工智能算法应用于三维/CG（计算机图形）数字媒体内容和创意营销领域，其核心算法可以实时控制、组合和驱动CG构建的数字化内容，让信息的表达和交流无缝融入虚拟或现实场景中，实现混合现实的智能化模拟、仿真融合和实时交互体验。-->
              迈吉客科技是中关村前沿技术企业和国家级高新技术企业，2016年开始多年专注于智能虚拟化技术，以具备多模态智能交互能力的数字形象为载体实现AI+相关行业应用场景落地，已形成SaaS模式的产品矩阵和智能服务平台，助力各领域合作伙伴降本提效增量创收，目前产品线和应用场景覆盖短视频内容生产、智能虚拟直播及服务机器人，数智营销等，已服务品牌营销、电商零售新消费、文旅/文博/文创、媒体政府、金融和大健康等领域合作伙伴。<br/>
              <br/>
              迈吉客科技获得了100余项CV/
              CG/NLP等相关发明专利、知识产权和行业奖项，希望通过自研核心技术减少人类重复性劳动，赋能相关领域合作伙伴共同为客户创造增量价值，帮助每家企业构建和沉淀智能数字资产，实现通往新一代互联网（元宇宙）的长期价值路径。
            </p>
          </div>
        </div>
      </u-animate>
    </u-animate-container>
  </div>
</template>

<script>
import { chatu01, chatu02 } from '@/assets/js/fixed_data'
import { mapGetters } from "vuex";

export default {
  name: "company",
  computed:{
    ...mapGetters(["isMobile",'clientWidth']),
  },
  data() {
    return {
      chatu01: chatu01,
      chatu02: chatu02
    }
  }
}
</script>

<style scoped lang="less">
.profileBox1 {
  width: 10.8rem;
  margin: 1.3rem auto 0;
  position: relative;

  .profileBox {
    padding: .49rem .45rem .45rem .45rem;
    z-index: 11;
    background: rgba(248, 248, 255, 1);
    box-shadow: 0px 2px 10px 0px rgba(205, 205, 255, 0.65);
    border-radius: 6px;

    .Profile-title {
      text-align: center;
      font-size: 28px;
      font-weight: 500;
      color: #333333;
    }

    .Profile-sub {
      text-align: center;
      font-size: 21px;
      font-family: Impact, Charcoal, sans-serif;
      font-weight: normal;
      color: #7776ff33;
      letter-spacing: 3px;
      font-style: italic;
      margin: 0.06rem 0 .26rem;
    }

    .Profile-content {
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      color: #706a71;
      line-height: 30px;
    }
  }

  .chatu01 {
    position: absolute;
    width: 1.91rem;
    height: .42rem;
    top: 0.95rem;
    right: -0.66rem;
    z-index: -1;
  }

  .chatu02 {
    position: absolute;
    width: 1.75rem;
    z-index: -1;
    left: -0.66rem;
    bottom: 0.83rem;
  }
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .profileBox1 {
    width: calc(100% - .4rem);
    height: 5rem;
    margin: .2rem;
    position: relative;

    .profileBox {
      height: calc(100% - 0.4rem);
      padding: .2rem;
      overflow: scroll;

      .Profile-title {
        font-size: 20px;
      }

      .Profile-sub {
        font-size: 14px;
        margin: 0.06rem 0 .12rem;
      }

      .Profile-content {
        font-size: 14px;
        line-height: 23px;
      }
    }
  }
}
</style>
