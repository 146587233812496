<template>
  <div class="honorBox">
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide class="honor_slide" v-for="(img,idx) in honor" :key="idx">
        <img class="honor_img" :src="img.url" alt="">
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { honor } from '@/assets/js/fixed_data'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  name: "honor",
  components: {
    swiper, swiperSlide
  },
  data() {
    return {
      honor: honor,
      swiperOption: {
        slidesPerView: 'auto',
        speed: 800,
        autoplay: {
          delay: 1000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        loop: true,
        centeredSlides: true,
        centeredSlidesBounds: true,
        slidesPerGroupAuto: true,
        spaceBetween: 20
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev'
        // }
      }
    }
  }
}
</script>

<style scoped lang="less">
.honorBox {
  height: 2rem;
  text-align: center;
  overflow: hidden;
}

.honor_container {
  white-space: nowrap;
  overflow: hidden;
}

.honor_container li {
  margin: 0 auto;
  list-style: none;
  display: inline-block;
  -webkit-animation: mymove 8s infinite;
  animation: scrollmymoveText1 8s infinite;
}

.honor_slide {
  display: flex;
  align-items: center;
  height: 2rem;
}

.honor_img {
  width: 100%;
  height: 1.6rem;
}

.swiper-slide {
  width: auto;
}

@-webkit-keyframes mymove {
  from {
    left: 0px;
  }
  to {
    left: -500px;
  }
}

.swiper-slide-active {
  .honor_img {
    transition: height .8s;
    -webkit-transition: height .6s; /* Safari */
    height: 2rem !important;
  }
}

.swiper-slide-prev {
  .honor_img {
    transition: height .8s;
    -webkit-transition: height .6s; /* Safari */
    height: 1.6rem !important;
  }
}
</style>
