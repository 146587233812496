<template>
  <div>
    <page-guide :options="options" :slogan="slogan" :pc-image="backgroundImage.pcImage" :mobile-image="backgroundImage.mobileImage"></page-guide>
    <!--    公司简介-->
    <company-profile></company-profile>
    <!--    创始人-->
    <div class="founder_box">
      <u-animate-container>
        <u-animate name="fadeInUp" delay="0s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
          <div class="titleBox titleBox2">
            <p class="title1">Magics创始人</p>
            <p class="title2">Magics Founder</p>
          </div>
        </u-animate>
      </u-animate-container>
      <founder></founder>
    </div>
    <!--    里程碑大事件-->
    <div style="display: none">
      <u-animate-container>
        <u-animate name="fadeInUp" delay="0s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
          <div class="titleBox">
            <p class="title1">里程碑大事件</p>
            <p class="title2">Milestones</p>
          </div>
        </u-animate>
      </u-animate-container>
      <timeline></timeline>
    </div>

    <div class="honor">
<!--      <u-animate-container>-->
<!--        <u-animate name="fadeInUp" delay="0s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">-->
<!--          <div class="titleBox">-->
<!--            <p class="title1">荣誉</p>-->
<!--            <p class="title2">Honor</p>-->
<!--          </div>-->
<!--        </u-animate>-->
<!--      </u-animate-container>-->
      <honor></honor>
    </div>

    <div class="aboutVideoBox">
      <trailer></trailer>
    </div>

    <div class="investorBox">
      <div class="brandBox">
        <ul class="investor-icon">
          <li v-for="(img,idx) in investorIcons" :key="idx" class="investor-con"><img :src="img" alt=""></li>
        </ul>
      </div>

    </div>
    <m-footer></m-footer>
  </div>
</template>

<script>
import pageGuide from '@/components/page-guide';
import companyProfile from "@/view/about/company";
import founder from "@/view/about/founder";
import timeline from "@/view/about/timeline";
import honor from "@/view/about/honor";
import trailer from "@/view/about/trailer";
import footer from "@/components/footer"
import { investorIcons } from "@/assets/js/fixed_data"

export default {
  name: "index",
  components: {
    pageGuide,
    companyProfile,
    founder,
    timeline,
    honor,
    trailer,
    'm-footer': footer
  },
  data() {
    return {
      options: [{
        content: 'Magics',
        animate: 'fadeIn',
        delay: '0s'
      }, {
        content: '让虚拟',
        animate: 'fadeIn',
        delay: '0.8s'
      }, {
        content: '照进',
        animate: 'fadeIn',
        delay: '1s'
      }, {
        content: '现实',
        animate: 'fadeIn',
        delay: '0.7s'
      }],
      slogan: 'Connect Reality with Virtuality',
      backgroundImage: {
        pcImage: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/backgroundother.png',
        mobileImage: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/02A.png'
      },
      investorIcons: investorIcons
    }
  }
}
</script>

<style lang="less" scoped>
.titleBox {
  text-align: center;
  padding: .6rem 0;
  font-size: 0;
  color: #333333;

  .title1 {
    text-align: center;
    font-size: 28px;
    font-weight: 500;
  }

  .title2 {
    font-size: 21px;
    font-family: Impact, Charcoal, sans-serif;
    font-weight: normal;
    color: #7776ff33;
    line-height: 31px;
    letter-spacing: 3px;
    font-style: italic;
    margin-top: 0.06rem;
    display: inline-block;
  }

  .title3 {
    margin-top: 0.16rem;
    font-size: .16rem;
    font-weight: 400;
  }
}

.titleBox2 {
  padding: 1.14rem 0 0;
}

.titleBox3 {
  padding: .66rem 0 .8rem 0;
}

.founder_box {
  margin-top: 1.4rem;
  background: url("https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/leodyBg.png") no-repeat;
  height: 8.31rem;
  width: 100%;
  background-size: 100% 100%;
}

.honor{
  //width: 90%;
  //margin: 1rem auto;
  background: #F8F8FF;
  padding: 20px 10px;
}
.aboutVideoBox {
  width: 800px;
  margin: 100px auto 0 auto;
  border-radius: 5px;
  overflow: hidden;
}

.investorBox {
  padding: .6rem 0;
}

.brandBox {
  text-align: center;
}

.investor-icon {
  //width: 12rem;
  margin: 0 auto;
}

.investor-icon li {
  display: inline-block;
  height: .81rem;
  padding: 0 .1rem;
}

.investor-icon li:nth-of-type(1) {
  width: 2.15rem;

}

.investor-icon li:nth-of-type(2) {
  width: 1.89rem;
}

.investor-icon li:nth-of-type(3) {
  width: 2.16rem;
}

.investor-icon li:nth-of-type(4) {
  width: 1.03rem;
}

.investor-icon li:nth-of-type(5) {
  width: 1.89rem;
}

.investor-icon li img {
  width: 100%;
  height: 100%;
}

.pshow {
  display: block;
}

.mshow {
  display: none;
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .pshow {
    display: none;
  }

  .mshow {
    display: block;
  }

  .titleBox {
    padding: .3rem 0;

    .title1 {
      font-size: 20px;
    }

    .title2 {
      font-size: 14px;
      letter-spacing: 2px;
    }

    .title3 {
      margin-top: 0.1rem;
      font-size: 16px;
    }
  }

  .titleBox2 {
    padding: 0.3rem 0 0;
  }

  .titleBox3 {
    padding: .3rem 0 .8rem 0;
  }

  .founder_box {
    margin-top: 0;
    height: 6.2rem;
  }

  .aboutVideoBox {
    width: calc(100% - .24rem);
    margin: .5rem auto 0 auto;
  }

  .investorBox {
    padding: .4rem 0;
  }

  .investor-icon {
    width: 100%;
    margin: 0 auto;
    font-size: 0;
  }

  .investor-icon li {
    display: inline-block;
    height: .5rem;
    padding: 0 .1rem;
  }

  .investor-icon li:nth-of-type(1) {
    width: 1rem;
  }

  .investor-icon li:nth-of-type(2) {
    width: 1rem;
  }

  .investor-icon li:nth-of-type(3) {
    width: 1rem;
  }

  .investor-icon li:nth-of-type(4) {
    width: 1.03rem;
  }

  .investor-icon li:nth-of-type(5) {
    width: 1rem;
  }

  .investor-icon li img {
    width: 100%;
    height: auto;
  }
}
</style>
